<template>
    <div class="safetyAssessment-dialog-main">
        <head-layout :head-title="formData.id?type=='detail'?'安全考核查看':'安全考核编辑':'安全考核新增'" :head-btn-options="headBtnOptions" @head-cancel="headCancel" @head-add="toSave"></head-layout>
        <el-form :model="formData" :rules="rules" ref="form" label-width="124px">
            <el-form-item label="考核对象" prop="assObject">
                <el-radio-group v-model="formData.assObject" @change="groupChange" :disabled="type=='detail'">
                    <el-radio label="人员"></el-radio>
                    <el-radio label="部门"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="人员" prop="userId" v-if="formData.assObject=='人员'">
                <el-input v-model="formData.userName" @focus="openDialog('userDialog')" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="deptId" v-else>
                <el-input v-model="formData.deptName" @focus="openDialog('deptDialog')" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="考核日期" prop="assTime">
                <el-date-picker type="date" placeholder="选择日期" v-model="formData.assTime" value-format="yyyy-MM-dd" :disabled="type=='detail'"></el-date-picker>
            </el-form-item>
            <el-form-item label="考核原因" prop="assReason" class="set-width">
                <el-input type="textarea" v-model="formData.assReason" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="扣分" prop="points">
                <el-input v-model.number="formData.points" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="经济处罚（元）" prop="fine">
                <el-input v-model.number="formData.fine" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="教育培训（学时）" prop="eduTrain">
                <el-input v-model.number="formData.eduTrain" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="待岗（月）" prop="awaitJob">
                <el-input v-model.number="formData.awaitJob" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="缴费期限" prop="paymentTime">
                <el-date-picker type="date" placeholder="选择日期" v-model="formData.paymentTime" value-format="yyyy-MM-dd" :disabled="type=='detail'"></el-date-picker>
            </el-form-item>
            <el-form-item label="考核描述" prop="assDesc" class="set-width">
                <el-input type="textarea" v-model="formData.assDesc" :disabled="type=='detail'"></el-input>
            </el-form-item>
            <el-form-item label="上传附件">
                <el-upload
                    :disabled="type=='detail'"
                    :class="{ uoloadSty: showImg, disUoloadSty: type=='detail' }"
                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                    accept="*"
                    :limit="1"
                    :file-list="fileArray"
                    :headers="headers"
                    :on-success="uploadSuccess"
                    :on-remove="handleRemove"
                    :on-exceed="uploadExceed"
                    :on-preview="handleFilePreview"
                    :before-upload="handleBeforeUpload"
                >
                <template v-if="type!='detail'">
                    <el-button size="small"
                    ><i class="el-icon-upload2"></i> 上传文件</el-button
                    >
                    <!-- <div slot="tip" class="el-upload__tip">
                    支持扩展名: .zip,.doc,.docx,.pdf,.png,.jpg
                    </div> -->
                </template>
                </el-upload>
            </el-form-item>
        </el-form>
        <el-dialog
        title="人员选择"
        :visible.sync="userDialog"
        width="80%"
        top="8vh"
        >
        <UserDetpDialog
            ref="UserDetpDialog"
            :dept-category="[2, 5]"
            @select-data="userBack"
        ></UserDetpDialog>
        </el-dialog>
        <el-dialog title="部门选择" :visible.sync="deptDialog" width="80%">
        <DeptDialog
            ref="DeptDialog"
            :deptCategory="[2, 5]"
            @select-data="deptBack"
        ></DeptDialog>
        </el-dialog>
        <fileView ref="fileView" title="附件预览"></fileView>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import { save,update ,detail} from '@/api/safetyAssessment/list'
import SingleFIleUpload from "@/components/file-upload/SingleFIleUpload";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import HeadLayout from "@/views/components/layout/head-layout";
export default {
    components: {
        SingleFIleUpload,DeptDialog,UserDetpDialog,HeadLayout
    },
    computed:{
        ...mapGetters(["userInfo"]),
    },
    data(){
        let isNumber = (rule, value, callback) => {
            if (value) {
                if (typeof (value) != 'number') {
                    callback(new Error('请输入数字'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        return{
            fileArray: [],
            userDialog:false,
            deptDialog:false,
            headBtnOptions: [{
                label: "保存",
                emit: "head-add",
                type: "button",
                icon: "",
                btnOptType: 'primary',
            }, {
                label: '返回',
                emit: "head-cancel",
                type: "button",
                icon: ""
            }],
            formData:{
                assObject:'人员',
                userId:'',
                userName:'',
                deptId:'',
                deptName:'',
                assTime:'',
                assReason:'',
                points:'',
                fine:'',
                eduTrain:'',
                awaitJob:'',
                paymentTime:'',
                assDesc:'',
                id:'',
                orgId:'',
                attachment:''
            },
            rules: {
                assObject: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
                userId: [{ required: true, message: '请选择人员', trigger: 'change' }],
                deptId: [{ required: true, message: '请选择部门', trigger: 'change' }],
                assTime: [{ required: true, message: '请选择考核日期', trigger: 'change' }],
                assReason: [{ required: true, message: '请输入考核原因', trigger: 'blur' }],
                points: [{ validator: isNumber, trigger: 'blur' }],
                fine: [{ validator: isNumber, trigger: 'blur' }],
                eduTrain: [{ validator: isNumber, trigger: 'blur' }],
                awaitJob: [{ validator: isNumber, trigger: 'blur' }],
            },
            type:''
        }
    },
    mounted(){
        let id = this.$route.query.id
        this.type = this.$route.query.type
        this.$nextTick(()=>{
            if(id){
                this.getDetail(id)
                if(this.type == 'detail'){
                    this.headBtnOptions.splice(0,1)
                }
            }else{
                this.formData.orgId = this.userInfo.dept_id
            }
        })
    },
    methods:{
        openDialog(key){
            this[key] = true
        },
        groupChange(){
            this.formData.userId = ''
            this.formData.userName = ''
            this.formData.deptId = ''
            this.formData.deptName = ''
        },
        userBack(row){
            this.formData.userId = row.id
            this.formData.userName = row.realName
            this.userDialog = false
        },
        deptBack(row){
            this.formData.deptId = row.id
            this.formData.deptName = row.deptName
            this.deptDialog = false
        },
        headCancel(){
            this.$refs.form.resetFields();
            this.formData.attachment = ''
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
        toSave(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let url = this.formData.id ? update : save
                    this.formData.attachment = JSON.stringify(this.fileArray)
                    url(this.formData).then(res=>{
                        if(res.data.code == 200){
                            this.$message.success('保存成功')
                            this.$emit('refresh')
                            this.$router.back();
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        getDetail(id){
            detail({id:id}).then(res=>{
                for(let key in this.formData){
                    this.formData[key] = res.data.data[key] == -1?'':res.data.data[key]
                }
                if(this.formData.attachment){
                    this.fileArray = JSON.parse(this.formData.attachment)
                }
            })
        },
        uploadExceed(limit, files, fileList, column) {
            // console.log(limit, files, fileList, column)
            this.$message.error("只能上传一个文件，请先删除当前文件");
        },
        // 上传之前的函数
        handleBeforeUpload(file) {
            // let fileName = file.name;
            // let pos = fileName.lastIndexOf(".");
            // let lastName = fileName.substring(pos, fileName.length).toLowerCase();
            // let arr = [".zip", ".doc", ".docx", ".pdf"];
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                this.$message.warning("上传文件大小不能超过 20MB!");
                return false;
            }
            // if (!arr.includes(lastName)) {
            //     this.$message.warning("不支持上传此类型文件");
            //     return false;
            // }
        },
        // 上传成功
        uploadSuccess(response, file, fileList) {
            this.fileArray = fileList.map((item) => {
                if (item.response) {
                    item.response.data.url = item.response.data.link;
                    item.response.data.name = item.response.data.originalName
                    return item.response.data;
                } else {
                    return item;
                }
            });
        },
        // 删除文件
        handleRemove(file, fileList) {
            this.fileArray = fileList.map((item) => {
                if (item.response) {
                    return item.response.data;
                } else {
                    return item;
                }
            });
        },
        handleFilePreview(item) {
            this.$refs.fileView.showFile(item.link)
        },
    },
}
</script>
<style scoped lang="scss">
    .safetyAssessment-dialog-main{
        background-color: #fff;
        overflow-y: auto;
        .el-form{
            display: table;
            margin-top: 20px;
            padding: 0 20px;
            .el-form-item{
                width: 50%;
                float: left;
                ::v-deep(.el-input.is-disabled .el-input__inner){
                    color: #C0C4CC!important;
                }
            }
        }
    }
    .set-width{
        width: 100%!important;
    }
    ::v-deep(.disUoloadSty){
        .el-upload--text{
            display: none;
        }
    }
</style>
